<template>
  <div class="fill-height white">
    <section class="statistic-section">
      <header
        class="statistic-section__header d-flex align-center mb-10 px-5 title font-weight-regular"
      >
        Statistics and Logs
      </header>
      <statistics-and-logs-files-list />
    </section>
  </div>
</template>

<script>
import StatisticsAndLogsFilesList from '@/components/engine/StatisticsAndLogsFilesList.vue';

export default {
  name: 'StatisticsAndLogs',
  components: { StatisticsAndLogsFilesList }
};
</script>

<style lang="scss" scoped>
.statistic-section {
  &__header {
    height: 80px;
  }

  &__cards-container {
    padding: 0 9% 0 4%;
  }
}

.card {
  &__button {
    border-bottom: 2px solid;
  }

  &__date-picker {
    &-container {
      width: 140px;
    }
  }
}
</style>
