<template>
  <v-card flat :height="180" :width="300" class="card d-flex flex-column mr-5 mb-5">
    <v-card-title class="card__title text-break subtitle-1 mb-1">{{ title }}</v-card-title>
    <v-card-text class="grow">
      <section class="d-flex">
        <div class="card__icon mr-3">
          <img :src="`/icons/file-types/${icon}.svg`" :alt="icon" />
        </div>
        <div class="card__info-section">
          <div
            class="card__file-name-label subtitle-2 light-blue--text text--darken-4 mb-2 text-break"
          >
            {{ fileName }}
          </div>
          <div
            v-if="additionalInformation"
            class="card__file-info-text caption blue-grey--text text--lighten-2"
          >
            {{ additionalInformation }}
          </div>
        </div>
      </section>
    </v-card-text>
    <slot name="actions"></slot>
  </v-card>
</template>

<script>
export default {
  name: 'EngineCard',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    additionalInformation: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #f9fafc;

  &__title {
    line-height: 19px;
  }

  &__file-name-label {
    line-height: 16px;
  }

  &__file-info-text {
    line-height: 14px;
  }
}
</style>
