<template>
  <div class="cards-container d-flex flex-wrap mb-5">
    <engine-card v-for="file in $options.statisticFilesWithDate" :key="file.type" v-bind="file">
      <v-card-actions slot="actions" class="d-flex justify-space-between px-4">
        <div class="card__date-picker-container">
          <v-menu
            v-model="datePicker[getDatePickerPropertyName(file.type)]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :nudge-bottom="10"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                append-icon="mdi-calendar-check"
                readonly
                outlined
                dense
                hide-details
                background-color="white"
                class="card_date-picker-text-field"
                :value="formattedStartDate[file.type]"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate[getStartDatePropertyName(file.type)]"
              no-title
              :max="maxDate"
              :type="getStartDatePickerType(file.type)"
              @input="closeDatePicker(file.type)"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-btn
          tile
          text
          class="card__button light-blue--text text--darken-4"
          @click="onDownloadStatisticClick(file)"
        >
          Download
        </v-btn>
      </v-card-actions>
    </engine-card>

    <engine-card v-for="file in $options.statisticFilesWithoutDate" :key="file.title" v-bind="file">
      <v-card-actions slot="actions" class="d-flex justify-end px-4">
        <v-btn
          tile
          text
          class="card__button light-blue--text text--darken-4"
          @click="onDownloadFileClick(file)"
        >
          Download
        </v-btn>
      </v-card-actions>
    </engine-card>
  </div>
</template>

<script>
import EngineCard from '@/components/engine/EngineCard.vue';
import StatisticFileBinding from '@/models/StatisticFileBinding';

import DateFormatService from '@/services/DateFormatService';
import { downloadMetrics } from '@/api/engine.api';
import { downloadFile } from '@/utils';

import {
  STATISTIC_AND_LOGS_FILE_TYPE,
  STATISTIC_FILES_WITH_DATE,
  STATISTIC_FILES_WITHOUT_DATE
} from '@/constants/engine';
import { ISO_DATE_FORMAT } from '@/constants/common';

const STATISTIC_FILE_PROPERTY_BINDING = {
  [STATISTIC_AND_LOGS_FILE_TYPE.QUESTIONNAIRE_STATISTIC]: new StatisticFileBinding({
    datePicker: 'questionnaireDatePicker',
    formattedStartDate: 'formattedQuestionnaireStatisticStartDate',
    startDate: 'questionnaireStatisticStartDate',
    type: 'date'
  }),
  [STATISTIC_AND_LOGS_FILE_TYPE.TREATMENT_STATISTIC]: new StatisticFileBinding({
    datePicker: 'treatmentDatePicker',
    formattedStartDate: 'formattedTreatmentStatisticStartDate',
    startDate: 'treatmentStatisticStartDate',
    type: 'date'
  }),
  [STATISTIC_AND_LOGS_FILE_TYPE.PHOTO_DIAGNOSTIC_STATISTIC]: new StatisticFileBinding({
    datePicker: 'photoDiagnosticDatePicker',
    formattedStartDate: 'formattedPhotoDiagnosticStatisticStartDate',
    startDate: 'photoDiagnosticStatisticStartDate',
    type: 'date'
  }),
  [STATISTIC_AND_LOGS_FILE_TYPE.ORDERS_STATISTIC]: new StatisticFileBinding({
    datePicker: 'ordersDatePicker',
    formattedStartDate: 'formattedOrdersStatisticStartDate',
    startDate: 'ordersStatisticStartDate',
    type: 'date'
  }),
  [STATISTIC_AND_LOGS_FILE_TYPE.RENEW_STATISTIC]: new StatisticFileBinding({
    datePicker: 'renewDatePicker',
    formattedStartDate: 'formattedRenewStatisticStartDate',
    startDate: 'renewStatisticStartDate',
    type: 'date'
  }),
  [STATISTIC_AND_LOGS_FILE_TYPE.DOCTOR_TREATMENTS_STATISTIC]: new StatisticFileBinding({
    datePicker: 'doctorTreatmentsDatePicker',
    formattedStartDate: 'formattedDoctorTreatmentsStartDate',
    startDate: 'doctorTreatmentsStartDate',
    type: 'month'
  })
};

export default {
  name: 'StatisticsAndLogsFilesList',
  components: { EngineCard },
  statisticFilesWithDate: STATISTIC_FILES_WITH_DATE,
  statisticFilesWithoutDate: STATISTIC_FILES_WITHOUT_DATE,
  data() {
    return {
      maxDate: null,
      datePicker: {
        questionnaireDatePicker: false,
        treatmentDatePicker: false,
        photoDiagnosticDatePicker: false,
        ordersDatePicker: false,
        renewDatePicker: false,
        doctorTreatmentsDatePicker: false
      },
      startDate: {
        questionnaireStatisticStartDate: '',
        treatmentStatisticStartDate: '',
        photoDiagnosticStatisticStartDate: '',
        ordersStatisticStartDate: '',
        renewStatisticStartDate: '',
        doctorTreatmentsStartDate: ''
      }
    };
  },
  computed: {
    formattedStartDate() {
      return {
        [STATISTIC_AND_LOGS_FILE_TYPE.QUESTIONNAIRE_STATISTIC]: this
          .formattedQuestionnaireStatisticStartDate,
        [STATISTIC_AND_LOGS_FILE_TYPE.TREATMENT_STATISTIC]: this
          .formattedTreatmentStatisticStartDate,
        [STATISTIC_AND_LOGS_FILE_TYPE.PHOTO_DIAGNOSTIC_STATISTIC]: this
          .formattedPhotoDiagnosticStatisticStartDate,
        [STATISTIC_AND_LOGS_FILE_TYPE.ORDERS_STATISTIC]: this.formattedOrdersStatisticStartDate,
        [STATISTIC_AND_LOGS_FILE_TYPE.RENEW_STATISTIC]: this.formattedRenewStatisticStartDate,
        [STATISTIC_AND_LOGS_FILE_TYPE.DOCTOR_TREATMENTS_STATISTIC]: this
          .formattedDoctorTreatmentsStartDate
      };
    },
    formattedQuestionnaireStatisticStartDate() {
      const date = new Date(this.startDate.questionnaireStatisticStartDate);
      return DateFormatService.formatDate(date);
    },
    formattedTreatmentStatisticStartDate() {
      const date = new Date(this.startDate.treatmentStatisticStartDate);
      return DateFormatService.formatDate(date);
    },
    formattedPhotoDiagnosticStatisticStartDate() {
      const date = new Date(this.startDate.photoDiagnosticStatisticStartDate);
      return DateFormatService.formatDate(date);
    },
    formattedOrdersStatisticStartDate() {
      const date = new Date(this.startDate.ordersStatisticStartDate);
      return DateFormatService.formatDate(date);
    },
    formattedRenewStatisticStartDate() {
      const date = new Date(this.startDate.renewStatisticStartDate);
      return DateFormatService.formatDate(date);
    },
    formattedDoctorTreatmentsStartDate() {
      const date = new Date(this.startDate.doctorTreatmentsStartDate);
      return DateFormatService.formatDate(date);
    }
  },
  created() {
    this.initMaxDate();
    this.setInitialStarDates();
  },
  methods: {
    initMaxDate() {
      this.maxDate = DateFormatService.formatDate(new Date(), ISO_DATE_FORMAT);
    },
    setInitialStarDates() {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);

      const formattedInitialDate = DateFormatService.formatDate(date, ISO_DATE_FORMAT);

      this.setStartDates(formattedInitialDate);
    },
    setStartDates(date) {
      this.startDate.questionnaireStatisticStartDate = date;
      this.startDate.treatmentStatisticStartDate = date;
      this.startDate.photoDiagnosticStatisticStartDate = date;
      this.startDate.ordersStatisticStartDate = date;
      this.startDate.renewStatisticStartDate = date;
      this.startDate.doctorTreatmentsStartDate = date;
    },
    getDatePickerPropertyName(type) {
      return STATISTIC_FILE_PROPERTY_BINDING[type].datePicker;
    },
    getStartDatePropertyName(type) {
      return STATISTIC_FILE_PROPERTY_BINDING[type].startDate;
    },
    getStartDatePickerType(type) {
      return STATISTIC_FILE_PROPERTY_BINDING[type].type;
    },
    closeDatePicker(type) {
      const datePickerPropertyName = this.getDatePickerPropertyName(type);
      this.datePicker[datePickerPropertyName] = false;
    },
    async onDownloadStatisticClick({ type, fileName }) {
      const date = this.startDate[this.getStartDatePropertyName(type)];
      const formatedDate =
        type === STATISTIC_AND_LOGS_FILE_TYPE.DOCTOR_TREATMENTS_STATISTIC ? new Date(date) : date;
      const { data } = await downloadMetrics({ type, date: formatedDate });

      downloadFile(data, fileName);
    },
    async onDownloadFileClick({ type, fileName }) {
      const { data } = await downloadMetrics({ type });

      downloadFile(data, fileName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/engine-shared.scss';
</style>
