import EngineConfigurationFileConfig from '@/models/EngineConfigurationFileConfig';
import EngineFileCard from '@/models/EngineFileCard';
import { FILE_ICON } from '@/constants/file-icons';

const ENGINE_FILE_TYPE = {
  DIAGNOSTIC: 'DIAGNOSTIC',
  TREATMENT: 'TREATMENT',
  FAST_FORMULATION_I: 'FAST_FORMULATION_I',
  FAST_FORMULATION_II: 'FAST_FORMULATION_II',
  DIRECTIONS_FOR_USE: 'DIRECTIONS_FOR_USE',
  INCI: 'INCI',
  DIAGNOSIS_SUMMARY: 'DIAGNOSIS_SUMMARY',
  FACE_DETECTOR: 'FACE_DETECTOR',
  SPIDER_CHART: 'SPIDER_CHART',
  TREATMENT_RULES: 'TREATMENT_RULES',
  ANOMALIES_AND_VALIDATIONS: 'ANOMALIES_AND_VALIDATIONS'
};

const ENGINE_CONFIGURATION_FILE_CONFIG = {
  DIAGNOSTIC: new EngineConfigurationFileConfig({
    title: 'Diagnostic',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.DIAGNOSTIC
  }),
  TREATMENT: new EngineConfigurationFileConfig({
    title: 'Treatment',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.TREATMENT
  }),
  FAST_FORMULATION_I: new EngineConfigurationFileConfig({
    title: 'Fast formulation I',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.FAST_FORMULATION_I
  }),
  FAST_FORMULATION_II: new EngineConfigurationFileConfig({
    title: 'Fast formulation II',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.FAST_FORMULATION_II
  }),
  DIRECTIONS_FOR_USE: new EngineConfigurationFileConfig({
    title: 'Directions for use',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.DIRECTIONS_FOR_USE
  }),
  INCI: new EngineConfigurationFileConfig({
    title: 'INCI',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.INCI
  }),
  DIAGNOSIS_SUMMARY: new EngineConfigurationFileConfig({
    title: 'Diagnosis PDF summary',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.DIAGNOSIS_SUMMARY
  }),
  FACE_DETECTOR: new EngineConfigurationFileConfig({
    title: 'Face Detector',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.FACE_DETECTOR
  }),
  SPIDER_CHART: new EngineConfigurationFileConfig({
    title: 'Spider chart',
    icon: FILE_ICON.PYTHON,
    type: ENGINE_FILE_TYPE.SPIDER_CHART
  }),
  TREATMENT_RULES: new EngineConfigurationFileConfig({
    title: 'Treatment engine rules',
    icon: FILE_ICON.EXCEL,
    type: ENGINE_FILE_TYPE.TREATMENT_RULES
  }),
  ANOMALIES_AND_VALIDATIONS: new EngineConfigurationFileConfig({
    title: 'Anomalies and validations',
    icon: FILE_ICON.EXCEL,
    type: ENGINE_FILE_TYPE.ANOMALIES_AND_VALIDATIONS
  })
};

const STATISTIC_AND_LOGS_FILE_TYPE = {
  QUESTIONNAIRE_STATISTIC: 'QUESTIONNAIRE_STATISTIC',
  TREATMENT_STATISTIC: 'TREATMENT_STATISTIC',
  DOCTOR_TREATMENTS_STATISTIC: 'DOCTOR_TREATMENTS_STATISTIC',
  PHOTO_DIAGNOSTIC_STATISTIC: 'PHOTO_DIAGNOSTIC_STATISTIC',
  ID_UNIVERSKIN_LOGS: 'ID_UNIVERSKIN_LOGS',
  ORDERS_STATISTIC: 'ORDERS_STATISTIC',
  RENEW_STATISTIC: 'RENEW_STATISTIC',
  PHOTO_STATISTIC: 'PHOTO_STATISTIC',
  CONSULTATION_FEE_STATISTIC: 'CONSULTATION_FEE_STATISTIC',
  REGIONAL_SALES_STATISTIC: 'REGIONAL_SALES_STATISTIC'
};

const STATISTIC_FILES_WITH_DATE = [
  new EngineFileCard({
    title: 'Questionnaire statistic CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.QUESTIONNAIRE_STATISTIC,
    fileName: 'questionnaires.csv',
    additionalInformation: 'Choose "FROM" date (+3 month statistic will be shown)'
  }),
  new EngineFileCard({
    title: 'Treatment statistic CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.TREATMENT_STATISTIC,
    fileName: 'treatment.csv',
    additionalInformation: 'Choose "FROM" date (+3 month statistic will be shown)'
  }),
  new EngineFileCard({
    title: 'Photo diagnostic statistic CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.PHOTO_DIAGNOSTIC_STATISTIC,
    fileName: 'photo-diagnostic.csv',
    additionalInformation: 'Choose "FROM" date (+3 month statistic will be shown)'
  }),
  new EngineFileCard({
    title: 'Orders statistic CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.ORDERS_STATISTIC,
    fileName: 'orders.csv',
    additionalInformation: 'Choose "FROM" date (+3 month statistic will be shown)'
  }),
  new EngineFileCard({
    title: 'Renew offer statistic CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.RENEW_STATISTIC,
    fileName: 'renew.csv',
    additionalInformation: 'Choose "FROM" date (+3 month statistic will be shown)'
  }),
  new EngineFileCard({
    title: "Doctor's list",
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.DOCTOR_TREATMENTS_STATISTIC,
    fileName: 'doctors-usage-report.csv',
    additionalInformation: 'Choose "FROM" month'
  })
];

const STATISTIC_FILES_WITHOUT_DATE = [
  new EngineFileCard({
    title: 'Overall logs of the Universkin backend application TXT',
    icon: FILE_ICON.TXT,
    type: STATISTIC_AND_LOGS_FILE_TYPE.ID_UNIVERSKIN_LOGS,
    fileName: 'universkin.log'
  }),
  new EngineFileCard({
    title: 'Photo table CSV',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.PHOTO_STATISTIC,
    fileName: 'photo.csv'
  }),
  new EngineFileCard({
    title: "Doctors' monthly invoices",
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.CONSULTATION_FEE_STATISTIC,
    fileName: 'dr-invoices.csv'
  }),
  new EngineFileCard({
    title: 'Regional sales report',
    icon: FILE_ICON.CSV,
    type: STATISTIC_AND_LOGS_FILE_TYPE.REGIONAL_SALES_STATISTIC,
    fileName: 'Regional-sales-report.xlsx'
  })
];

export {
  ENGINE_FILE_TYPE,
  ENGINE_CONFIGURATION_FILE_CONFIG,
  STATISTIC_AND_LOGS_FILE_TYPE,
  STATISTIC_FILES_WITH_DATE,
  STATISTIC_FILES_WITHOUT_DATE
};
